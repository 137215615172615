<template>
  <div class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>业务中台</el-breadcrumb-item>
           <el-breadcrumb-item>商城管理</el-breadcrumb-item>
           <el-breadcrumb-item>商城装修</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
    <div class='content-box'>
      <!-- 表头 -->
      <el-row>
        <h1 class="title">提示：本页面主要针对移动端进行内容设置，主要涉及微信小程序，微信H5商城，抖音小程序等应用</h1>
      </el-row>
      <!-- 主体内容 -->
      <div class="card-box">
        <el-form ref="formData" :model="formData" :rules="rules" label-width="110px">
          <el-form-item label="门店名字"  prop="hotelName">
            <el-input v-model="formData.hotelName" ></el-input>
          </el-form-item>

          <el-form-item label="主题颜色" prop="themeColor">
            <el-color-picker v-model="formData.themeColor"></el-color-picker>
          </el-form-item>
          <div class="imglist-box" v-for="(item,index) in imglist" :key="index" @click="getimginfo(index)">
            <el-form-item   :label="item.name" :prop="item.type" >
              <p>{{item.text}}</p>
              <div style="display: flex;justify-content: center; align-items: center;">

                <div class="img-box" v-if="imgboxshow" >
                  <div class="img-list" v-if="item.imageUrl != '' "  style="position: relative;margin: 10px;">
                    <img :src="item.imageUrl" style="width: 320px;"  />
                  </div>
                </div>
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :limit="1"
                    :ref="'upload'+index"
                    :show-file-list="false"
                    :on-change="handleChange"
                    :auto-upload="false"
                    :multiple="false"
                    style="z-index: 999;margin-left: 35px;"
                >
                  <i  class="el-icon-upload avatar-uploader-icon"></i>
                </el-upload>
              </div>

            </el-form-item>
          </div>
          <el-form-item label="" prop="Themecolor">
            <el-button class="bg-gradient" style="width: 200px; font-size: 24px;" @click="submitform" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>

</template>

<script>
import { urlObj } from '@/api/interface'
import { mapState } from 'vuex'
export default {
  computed:{
      ...mapState(['hotelInfo'])
  },
  watch:{
      hotelInfo:{
        handler(newVal,oldVal){
            if(newVal.id !== oldVal.id && oldVal){
              this.gethotelthemeinfo()
            }
        }
      }
  },
  name: 'ThemeOfMall',
  data(){
    return{
      imgboxshow:false,
      file:'',
      imgindex:'',
      hotelinfo:'',
      formData:{
        id:'',
        hotelCode:'',
        content:{
           themeColor:'',
           homePageImg:'',
           storeImg:'',
           serviceImg:'',
        },
        themeColor:'',
        homePageImg:'',
        hotelName:'',
        storeImg:'',
        serviceImg:'',
      },
      imglist:[
        {name:'首页主图',type:'homePageImg',imageUrl:'',text:'图片主要用于移动端首页主图显示，建议尺寸为640pxX370px，大小小于500k'},
        {name:'门店主图',type:'storeImg',imageUrl:'',text:'图片主要用于移动端酒店门店头部显示，建议尺寸为640pxX270px，大小小于500k'},
        {name:'服务主图',type:'serviceImg',imageUrl:'',text:'图片主要用于移动端服务板块头部显示，建议尺寸为640pxX270px，大小小于500k'},
      ],
      //校验
      rules: {
        hotelName: [
          {required: true, message: '请输入门店名称', trigger: 'blur'},
        ],
        // themeColor: [
        //   {required: true, message: '请选择主题颜色', trigger: 'blur'},
        // ],
        homePageImg: [
          {required: true, message: '请上传首页主图', trigger: 'blur'},
        ],
        storeImg: [
          {required: true, message: '请上传门店主图', trigger: 'blur'},
        ],
        serviceImg: [
          {required: true, message: '请上传服务主图', trigger: 'blur'},
        ],


      }
    }
  },
  created () {
    this.hotelinfo =  JSON.parse(sessionStorage.getItem('hotelInfo'))
  },
  mounted() {
    this.formData.id   = this.hotelInfo.id
    this.gethotelthemeinfo()
  },
  methods: {
    //提交表单
    submitform(){
       if (this.imglist[0].imageUrl == ''){
          this.$message.error('请上传首页主图')
       }
       if (this.imglist[1].imageUrl == ''){
          this.$message.error('请上传门店主图')
       }
       if (this.imglist[2].imageUrl == ''){
          this.$message.error('请上传服务主图')
       }
       this.formData.content.themeColor   = this.formData.themeColor
       this.formData.content.homePageImg =  this.imglist[0].imageUrl
      this.formData.content.storeImg =  this.imglist[1].imageUrl
      this.formData.content.serviceImg =  this.imglist[2].imageUrl
       this.formData.hotelCode = this.hotelinfo.hotelCode
       this.formData.content = JSON.stringify(this.formData.content)
          const url = urlObj.edithoteltheme
          const param = this.formData
          this.$axios.post(url, param).then(async res => {
            if (res.success) {
          this.$message.success('保存成功')
               this.gethotelthemeinfo()
            }
          }).catch(error => {
            this.$message.error(error)
          })
    },
    gethotelthemeinfo(){
      const url = urlObj.gethotelthemeinfo+'/'+this.hotelInfo.id
      const param = {}
      this.$axios.get(url, param).then( res => {
         let  content = res.records.content
           this.formData.content = JSON.parse(content)
           this.formData.hotelName = this.hotelInfo.hotelName
         this.formData.themeColor  = this.formData.content.themeColor
         this.imglist[0].imageUrl = this.formData.content.homePageImg || ''
          this.imglist[1].imageUrl = this.formData.content.storeImg || ''
          this.imglist[2].imageUrl = this.formData.content.serviceImg || ''
          this.imgboxshow = true
      })
    },
    //获取上传组件的下标
    getimginfo(i){
      this.imgindex = i

    },
    //图片上传
    handleChange(file) {
      this.file = file.raw
      const url = urlObj.uploadDetailFile;
      let param = new FormData();
      param.append("file", this.file); // 文件
      param.append('bizId','default')
      param.append('module','event')
      param.append('menu','distributor')
      param.append('func','image')
      param.append('category','image')
      param.append('isThumb',true)
      this.$axios.post(url, param, 'file')
          .then((res) => {
            this.imgboxshow = false
            this.imglist[this.imgindex].imageUrl = window.getResourcesHost()+res.records.location+'/'+res.records.formatName
            let upload = "upload"+this.imgindex
            this.$refs[upload][0].clearFiles()
            this.imgboxshow = true
      //
          });
    },


  },
}
</script>
<style scoped>
.cont{
  min-width: 1350px;
}
.title{
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;
  color:#2d2d2d;
}
.card-box{
  margin: 30px 65px;
  display: flex;
  flex-wrap:wrap;
}

.card-left img{
  width: 100px;
}
.imglist-box p{
  padding: 0 10px;
  font-size: 16px;
  opacity: 0.5;
}
.card-right :nth-child(2){
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-bottom button{
  width: 29%;
}
/deep/.avatar-uploader-icon{
   font-size: 45px;
   color: #8c939d;
   width: 100px;
   height: 100px;
   line-height: 100px;
   text-align: center;
}
/deep/.el-form-item__label{
  font-size:16px;
}

</style>
